.sidebar {
  &:global(.not-visible) {
    @apply opacity-0 h-0 overflow-hidden;
  }
}

.component {
  @apply absolute w-full h-full pb-20 pointer-events-none;
}

.wrapper {
  @apply sticky hidden transition-opacity duration-200 xl:block top-34 pointer-events-none z-20;
  -webkit-transform: translate3d(0, 0, 0);
}

.cta {
  &:global(.plate) {
    @apply w-57 px-6 py-5 overflow-hidden rounded-sm absolute;
  }

  &:global(.floating) {
    @apply p-4;
  }

  &:global(.outlined) {
    @apply border-black-100/20 border rounded bg-white;
  }

  &:global(.theme-white) {
    @apply bg-white;
  }

  &:global(.theme-lime) {
    @apply bg-summer-green-50;
  }

  &:global(.theme-green) {
    @apply bg-green-50;
  }

  &:global(.theme-dark-green) {
    @apply bg-green-100;
  }

  &:global(.theme-yellow) {
    @apply bg-campaign-yellow-100;
  }

  &:global(.theme-light-lime) {
    @apply bg-campaign-light-lime-100;
  }

  &:global(.theme-cyan) {
    @apply bg-dark-cyan-100;
  }

  &:global(.theme-light-cyan) {
    @apply bg-dark-cyan-50;
  }

  &:global(.theme-black) {
    @apply bg-black-100 text-white;
  }

  &:global(.theme-forest-green) {
    @apply bg-campaign-forest-green-100 text-white;
  }

  & :global(.text) {
    @apply pb-4 font-sans text-sm leading-normal;
  }

  & a {
    @apply underline;
    &:not(.btn) {
      @apply no-underline;
    }
  }

  & :global(.btn) {
    white-space: normal;
  }

  & h2 {
    @apply mb-2 font-sans text-xl font-medium leading-snug;
  }
}

.float-wrapper {
  @apply fixed w-full bottom-0 z-90 xl:hidden pointer-events-auto;

  & h2 {
    @apply text-base mb-0;
  }

  & :global(.text) {
    @apply text-sm pb-2.5;
  }
}

@media print {
  .component {
    @apply hidden !important;
  }
}
