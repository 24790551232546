.input {
  @apply w-full px-3 py-2 text-black-100 transition-colors duration-200 border border-black-100/20 rounded appearance-none sm:px-4 focus:outline-none placeholder-black-50;

  &:global(.has-error) {
    @apply border-red-100;
  }
}

.textarea {
  @apply h-40 px-4 py-3 border border-black-100/20 rounded appearance-none resize-none placeholder-black-50 focus:outline-none;

  &:global(.has-error) {
    @apply border-red-100;
  }
}

.disabled {
  background-color: #f9f9f9;
  border: 1px solid #b3b3b3;
  pointer-events: none;
}
