.spinner {
    @apply ease-linear rounded-full animate-spin;
}

.default {
    @apply w-8 h-8 border-4;
}

.button {
    @apply border-4 w-7 h-7;
}

.small {
    @apply w-5 h-5 border-3;
}

.mini {
    @apply w-4 h-4 border-3;
}

.large {
    @apply w-24 h-24 border-6;
}

.giant {
    @apply w-48 h-48 border-6;
}