.progressWrapper {
  @apply block relative w-full h-2 bg-summer-green-25 mb-5.5 rounded-full;
}

.progressBar {
  @apply absolute h-2 rounded-full left-0 top-0 bg-green-100;
}

.quizWrapper {
  & :global(label) {
    @apply relative z-10 border border-black-25 block p-4 cursor-pointer border-b;
    -webkit-tap-highlight-color: transparent;
    margin-top: -1px;

    &:first-of-type {
      @apply rounded-t;
    }
    &:last-of-type {
      @apply rounded-b;
    }

    &:global(.selected) {
      @apply bg-green-25 border-green-100 z-20;

      &:global(.is-incorrect) {
        @apply bg-red-100/25 border-red-100/50;
      }
    }

    & :global(.checkmark-circle.is-incorrect) {
      background: #af3e3c;
      & svg {
        @apply fill-[#ddd];
      }
    }

    & :global(.answer-icon) {
      @apply flex-grow pr-[8px] text-right;

      &:global(.is-correct) {
        @apply pr-[6px];
      }
    }
  }

  & :global(.hint-wrapper) {
    @apply mt-4 text-base relative;

    & :global(.toggle) {
      @apply cursor-pointer;
    }

    & :global(.lightbulb) {
      @apply inline-block mr-2;
    }

    & :global(.chat-bubble) {
      @apply hidden relative w-full mt-4 bg-black-100 text-white z-100 rounded p-4 pointer-events-none;

      &:global(.is-active) {
        @apply block;
      }

      &:before {
        @apply absolute content-[""] -top-2 left-2.5;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid black;
      }
    }
  }
}

.pageWrapper {
  & :global(.has-divider) {
    @apply pb-10 border-b border-black-100/5;

    &:last-of-type {
      @apply pb-0 border-none;
    }
  }
}

.contentImage {
  @apply block bg-black-100/20 mb-4.5;

  &:global(.not-article) {
    @apply lg:mb-0;
  }

  &:global(.format-square) {
    @apply aspect-w-1 aspect-h-1;
  }

  &:global(.format-4-3) {
    @apply aspect-w-4 aspect-h-3;
  }

  &:global(.format-16-9) {
    @apply aspect-w-16 aspect-h-9;
  }
}

@media screen(lg) {
  .pageWrapper {
    & :global(.has-extra-content) {
      @apply flex align-top justify-between;
    }

    & :global(.is-section) {
      width: calc(50% - 40px);
    }
  }

  .quizWrapper {
    & :global(.hint-wrapper .chat-bubble) {
      @apply absolute block max-w-420 opacity-0 transition-opacity duration-300;
      width: max-content;

      &:global(.is-active) {
        @apply opacity-100;
      }
    }

    & :global(.explanation) {
      @apply pt-4 text-lg;
      & a {
        @apply underline;
      }
    }
  }
}
