.gravityForm {
  @apply rounded;

  & :global(form) {
    @apply w-full;
  }

  &:global(.has-grid) {
    & form {
      @apply grid w-full gap-10 grid-rows-1 lg:grid-cols-2;
    }
  }

  & :global(.field-consent) {
    & a {
      @apply underline;
    }
  }

  & :global(.confirmation) {
    @apply md:min-h-xl;
  }

  & :global(.confirmation a) {
    @apply underline;
  }

  & :global(.confirmation .gform_confirmation_wrapper) {
    @apply px-4 pt-4 pb-8 md:px-0;
  }

  & :global(.confirmation .custom-duplicate h3) {
    @apply pb-6;
  }

  & :global(.confirmation .gform_confirmation_wrapper p) {
    @apply pb-6;
  }

  & :global(.confirmation .button) {
    @apply bg-summer-green-100 text-black-100 hover:bg-summer-green-20 hover:border-summer-green-100;
    @apply px-4 py-3 mx-auto font-serif text-base font-bold;
    @apply inline-block my-2 mr-2 leading-none transition-colors duration-200 border-2 border-transparent rounded no-underline;
  }

  & :global(.gquiz-field-label) {
    @apply font-bold mb-2;
  }

  & :global(.gquiz-field-choice) {
    & li {
      @apply px-3 py-2 flex justify-between;
      &:nth-child(odd) {
        background-color: rgba(0, 0, 0, 0.04);
      }
      &:global(.gquiz-correct-choice) {
        @apply bg-green-10;
      }
      &:global(.gquiz-incorrect-response) {
        @apply bg-red-10;
      }
    }
    & :global(.green-circle) {
      @apply mt-0.5 w-5 h-5 bg-green-100 rounded-full flex justify-center items-center;
    }
    & :global(.red-circle) {
      @apply mt-0.5 w-5 h-5 bg-red-100 rounded-full flex justify-center items-center;
    }
    & img {
      @apply w-2 h-2;
    }
  }

  & :global(.custom-answer-indicator) {
    @apply mt-1.5 block w-3 h-3 bg-black-100/30 rounded-full;
  }

  & :global(.gquiz-answer-explanation) {
    @apply mt-4;
  }

  & :global(.extra-content) {
    & figure {
      @apply relative w-full h-full overflow-hidden rounded bg-black-100/10;
    }

    &:global(.is-4-3) {
      & figure {
        @apply aspect-w-4 aspect-h-3 h-auto w-auto;
      }
    }

    &:global(.is-16-9) {
      & figure {
        @apply aspect-w-16 aspect-h-9 h-auto w-auto;
      }
    }

    &:global(.is-square) {
      & figure {
        @apply aspect-w-1 aspect-h-1 h-auto w-auto;
      }
    }

    & :global(.spinner) {
      @apply flex items-center justify-center w-full h-full;
    }
  }
}

.modalButton {
  @apply absolute outline-none;
  @apply flex justify-center items-center z-100;
  @apply right-2 top-2 sm:right-3 sm:top-3 md:-right-5 md:-top-5 w-10 h-10;

  & :global(.close) {
    @apply bg-black-100 rounded-full text-white cursor-pointer;
    @apply flex justify-center items-center;
    @apply w-10 h-10 md:w-8 md:h-8;

    & :global(.icon) {
      @apply fill-white w-[12px];
    }
  }
}

.modal {
  @apply absolute bg-white top-1/2 left-1/2 right-auto bottom-auto max-w-8xl;
  @apply p-4 sm:p-6 md:p-8 xl:p-12 outline-none rounded;
  transform: translate(-50%, -50%);
  width: calc(100% - 2rem);
  max-height: calc(100% - 2rem);

  @screen md {
    width: calc(100% - 4rem);
  }
}

.modal-tf_vote {
  @apply flex flex-col ml:flex-row;
}
