.wrapper {
  display: flex;
  align-items: center;

  & :global(.circle) {
    @apply flex items-center justify-center rounded-full w-icon-lg h-icon-lg cursor-pointer;
    @apply border hover:border-summer-green-100 hover:bg-summer-green-20 border-black-100/20;

    &:global(.liked) {
      @apply bg-summer-green-50 hover:bg-summer-green-50 border-summer-green-100;
    }

    &:global(.error) {
      @apply bg-red-100 border-red-110 hover:bg-red-110;
    }
  }

  & :global(.text) {
    @apply text-sm ml-3;
  }
}
