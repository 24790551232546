.wrapper {
  & p {
    @apply max-w-1xl;
  }

  & ul {
    @apply grid grid-cols-1 md:grid-cols-2 gap-8;

    &:global(.cols-3) {
      @apply grid-cols-1 md:grid-cols-2 lg:grid-cols-3;
    }
  }

  & :global(.button-wrapper) {
    @apply mt-8 md:mt-12 text-center;

    & a {
      @apply inline-block w-full md:max-w-xs;
    }
  }
}
